import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import bubbleData from "./bubbleData.json";
import bubbleItem from "./bubbleItem.json";
import Zoom from "../../../../assets/images/zoom.png";
import restrict from "../../../../assets/images/restrict.png";
import Downarrow from "../../../../assets/images/downarrow.png";
import Sidebar from "./SideBar";
import BreadCrumb from "./BreadCrumb";
import Footer from "./footer";
import FooterTable from "./FooterTable";
import Dropdown from "./Dropdown";
import BubbleChart from "./BubbleChart";

function Index() {
    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [tableData, setTableData, tableDataRef] = useState({});
    const [graphData, setGraphData] = useState([]);
    const [bubbleItemData, setBubbleItemData] = useState(bubbleItem);
    const [selectedIndex, setSelectedIndex, selectedIndexRef] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [bubbleSelected, setBubbleSelected] = useState(false);
    const [bubbleFlag, setBubbleFlag, bubbleFlagRef] = useState(false);

    const tableRef = useRef(null);
    const chartRef = useRef(null);

    // get Bubble list data
    const getBubbleList = (bubble) => {
        makeData(bubbleData); //called prepare bubble data
        if (bubble.length) {
            let key = bubble[0].key;

            setBubbleItemData(bubbleItem);

            let tableFilter = bubbleItem.filter((ele) => {
                return ele?.key === key;
            });

            let bubbleFilter = bubble.filter((element) => {
                return element?.key === key;
            });

            let obj = {
                count: bubbleFilter[0].count,
                value: bubbleFilter[0].key,
                color: bubbleFilter[0].color,
            };

            tableFilter[0].lejant = obj;

            setTableData(tableFilter[0]);
        }
    };

    //prepare bubble data
    const makeData = (datas) => {
        if (datas.length) {
            const highestCount = datas.reduce(function (highest, current) {
                return highest.count > current.count ? highest : current;
            });

            //setData(datas); changes on 28-03-2023
            highestCount["z"] = 100;
            highestCount["name"] = highestCount.key + " <br />" + highestCount.count;

            datas.unshift(highestCount);

            const uniqueData = datas.filter(
                (key, index, array) => array.findIndex((k) => k.key == key.key) == index
            );

            uniqueData.map((element) => {
                element.name = element.key + " <br />" + element.count;
            });
            setGraphData(uniqueData);
            setData(uniqueData);
        }
        setRefresh(true);
    };

    //useEffect
    useEffect(() => {
        console.log(tableData, bubbleItemData, bubbleFlag); //this need to be include for use react-usestateref package, othewise es lint throw error
        setBubbleSelected(false);
        getBubbleList(bubbleData);
    }, []);

    // this fuction  for table data filter
    const tableHandleChange = (id, keyName) => {
        let myArray = [];
        myArray = keyName.split(" ");

        setBubbleItemData(bubbleItem);

        let tableFilter = bubbleItem.filter((ele, index) => {
            if (myArray.length ? true : false) {
                return ele?.key === myArray[0];
            } else {
                return index === id;
            }
        });

        let bubbleFilter = data.filter((element, index) => {
            return index === id;
        });

        let obj = {
            count: bubbleFilter[0].count,
            value: bubbleFilter[0].key,
            color: bubbleFilter[0].color,
        };

        tableFilter[0].lejant = obj;
        setBubbleSelected(true);
        setTableData(tableFilter[0]);
        selectedIndexRef.current === id
            ? setBubbleFlag(!bubbleFlagRef.current)
            : setBubbleFlag(false);
        selectBubbleElement(id);
        setSelectedIndex(id);
    };

    // Mesele dropdown select function
    const handleOptions = (selectedOption) => {
        if (selectedOption === null) {
            setSelectedOption("");
            setBubbleFlag(true);
            selectBubbleElement(selectedIndexRef.current);
        } else {
            setSelectedOption(selectedOption);
        }
    };

    // Scroll to table & chart view
    const scrollView = (type) => {
        if (type === "table") {
            window.scrollTo({
                top: tableRef.current.offsetTop,
                behavior: "smooth",
                block: "top",
            });
        } else {
            window.scrollTo({
                top: chartRef.current.offsetTop,
                behavior: "smooth",
                block: "top",
            });
        }
    };

    //Bubble element alpha property add and remove
    const selectBubbleElement = (id) => {
        let value = data.map((item, index) => {
            if (selectedIndexRef.current === id && bubbleFlagRef.current) {
                item.alpha = "100";
                setBubbleSelected(false);
                return item;
            } else {
                if (index == id) {
                    item.alpha = "100";
                    return item;
                } else {
                    item.alpha = "20";
                    return item;
                }
            }
        });
        // this method use by give unique data in array
        const uniqueData = value.filter(
            (key, index, array) => array.findIndex((k) => k.key == key.key) == index
        );
        setGraphData(uniqueData);
        setData(uniqueData);
    };

    // It's function for remove selected bubble element and table data blank
    const removeBubbleElement = () => {
        setBubbleFlag(true);
        selectBubbleElement(selectedIndexRef.current);
    };

    return (
        <div className="bubblecart">
            {/*LeftChart*/}
            <div>
                <BreadCrumb />
            </div>

            <div className="content">
                {/*top section*/}
                <div
                    className="p-4 d-flex align-items-center justify-content-between border-bottom position-sticky bg-white"
                    style={{ zIndex: "999", top: "0" }}
                >
                    <Dropdown
                        selectedOption={selectedOption}
                        handleOptions={(selectedOption) => handleOptions(selectedOption)}
                    />
                    <div>
                        <a href="#" className="p-2 ps-3 border-start">
                            <img src={Zoom} alt="Zoom" className="align-bottom" />
                        </a>
                    </div>
                </div>
                {/*top section*/}

                {/*Chart section*/}
                <div className="p-relative d-flex">
                    <div className="content__area">
                        <div className="chart_content">
                            <div className="content_graph">
                                {selectedOption !== "" && graphData.length ? (
                                    <div ref={chartRef}>
                                        <BubbleChart
                                            refresh={refresh}
                                            bubbleChart={graphData}
                                            tableHandleChange={(id, keyName) =>
                                                tableHandleChange(id, keyName)
                                            }
                                        />
                                        {bubbleSelected ? (
                                            <div
                                                className="view_table"
                                                onClick={() => scrollView("table")}
                                            >
                                                <span>View Table</span>
                                                <img src={Downarrow} />
                                            </div>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className="restrict">
                                        <img
                                            src={restrict}
                                            alt="restrict"
                                            className="restrict__img"
                                        />
                                    </div>
                                )}
                            </div>
                            {selectedOption !== "" &&
              tableDataRef.current &&
              bubbleSelected ? (
                                    <div className="footer_table position-relative" ref={tableRef}>
                                        <div
                                            className="view_chart"
                                            onClick={() => scrollView("chart")}
                                        >
                                            <img src={Downarrow} />
                                            <span>Chart</span>
                                        </div>
                                        <FooterTable
                                            tableData={
                                                selectedOption !== "" && tableDataRef.current
                                                    ? tableDataRef.current
                                                    : []
                                            }
                                            bubbleSelected={bubbleSelected}
                                            removeBubbleElement={removeBubbleElement}
                                        />
                                    </div>
                                ) : null}
                        </div>
                    </div>
                    {/*Chart section*/}

                    <Sidebar
                        sideBarName={selectedOption !== "" && data.length > 0 ? data : []}
                        tableHandle={(id, keyName) => tableHandleChange(id, keyName)}
                        selectedIndex={selectedIndex}
                        bubbleSelected={bubbleSelected}
                    />
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
}

export default Index;
